<template>
  <v-dialog width="900" v-model="dialog" :fullscreen="$vuetify.breakpoint.xs">
    <template v-slot:activator="{ on }">
      <v-list-item
        v-if="$store.getters['auth/isTattooer'] && false"
        class="cusList"
        @click="fetchVacations"
        v-on="on"
      >
        <v-list-item-icon>
          <v-icon small style="margin-top: -4px !important"
            >$edit</v-icon
          ></v-list-item-icon
        ><v-list-item-title>
          <span>{{ $t("vacations.title") }}</span>
        </v-list-item-title>
      </v-list-item>
      <v-btn
        class="ml-n3"
        style="height: 15px"
        v-if="$store.getters['auth/isStudio']"
        @click="fetchVacations"
        v-on="on"
        icon
        ><v-icon small>$eye</v-icon></v-btn
      >
    </template>
    <v-card id="horario">
      <v-card-title
        style="text-decoration: underline; text-transform: uppercase"
        >{{ $t("vacations.title") }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row>
          <v-col
            v-if="$store.getters['auth/isTattooer']"
            class="py-0"
            align="right"
            cols="12"
          >
            <FormCloseDaysTattooer
              ref="form"
              :studio="studio"
              @save="fetchVacations"
            ></FormCloseDaysTattooer>
          </v-col>
          <v-col class="py-0" cols="12">
            <v-data-table
              :footer-props="{
                'items-per-page-text': $vuetify.breakpoint.smAndDown
                  ? ''
                  : $t('items_per_page'),
              }"
              id="customer_table"
              :headers="headers"
              :items="items"
              :options.sync="options"
              :server-items-length="totalDays"
              :loading="loading"
              :sort-by.sync="sortBy"
            >
              <template v-slot:item.reason="{ item }">
                <span v-if="item.reason == null">-</span>
                <span v-else>{{ item.reason }}</span>
              </template>
              <template v-slot:item.start_date="{ item }">
                <span>{{ $d(new Date(item.start_date), "dateShort") }}</span>
              </template>
              <template v-slot:item.end_date="{ item }">
                <span>{{ $d(new Date(item.end_date), "dateShort") }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on="on" v-bind="attrs" large
                      >mdi-dots-horizontal</v-icon
                    >
                  </template>
                  <v-list>
                    <v-list-item
                      class="cusList"
                      @click="
                        $refs.form.id = item.id;
                        $refs.form.openEdit();
                      "
                    >
                      <v-list-item-icon>
                        <v-icon small style="margin-top: -5px !important"
                          >$edit</v-icon
                        ></v-list-item-icon
                      ><v-list-item-title>
                        <span>{{
                          $t("vacations.edit")
                        }}</span></v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item class="cusList" @click="del(item.id)">
                      <v-list-item-icon>
                        <v-icon small style="margin-top: -5px !important"
                          >$delete</v-icon
                        ></v-list-item-icon
                      ><v-list-item-title>
                        <span>{{
                          $t("vacations.delete")
                        }}</span></v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "CloseDays",
  props: ["studio", "tattooer"],
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: this.$t("vacations.date_start"),
          align: "center",
          sortable: false,
          value: "start_date",
        },
        {
          text: this.$t("vacations.date_end"),
          align: "center",
          sortable: false,
          value: "end_date",
        },
        {
          text: this.$t("vacations.reason"),
          align: "center",
          sortable: false,
          value: "reason",
        },

        this.$store.getters["auth/isTattooer"]
          ? {
              text: this.$t("actions"),
              align: "center",
              sortable: false,
              value: "actions",
            }
          : "",
      ],
      items: [],
      totalDays: 0,
      filters: {
        search: "",
        dates: "",
        tattooers: "",
      },
      options: {},
      loading: true,
      menu: false,
      sortBy: "first_name",
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchVacations();
      },
      deep: true,
    },
  },
  mounted() {
    if (this.$store.getters["auth/isStudio"]) {
      this.filters.studio = this.$store.state.auth.user.studio.id;

      this.filters.tattooer = this.tattooer.id;
    } else {
      this.fetchVacations();
    }
  },
  methods: {
    ...mapActions("close_days_tattooer", ["getVacations", "deleteVacation"]),

    fetchVacations() {
      this.loading = true;
      this.getVacations({
        pagination: this.options,
        filters: this.$store.getters["auth/isTattooer"]
          ? {
              studio: this.studio.id,
            }
          : this.filters,
      }).then((vacations) => {
        this.items = vacations.data;
        console.log(this.items);
        this.totalDays = vacations.total;
        this.loading = false;
      });
    },
    del(value) {
      console.log(value);
      this.deleteVacation({ id_vacation: value }).then((data) => {
        if (data) {
          this.fetchVacations();
        }
      });
    },
  },
  components: {
    FormCloseDaysTattooer: () =>
      import("@/components/user/settings/profile/FormCloseDaysTattooer"),
  },
};
</script>

<style lang="sass">
#horario
  @media (min-width: 400px)
    th:first-child
      min-width: 90px !important
      max-width: 90px !important
    td:first-child
      min-width: 90px !important
      max-width: 90px !important
    td:nth-child(3)
      min-width: 90px !important
      max-width: 90px !important
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
.cusList
  height: 30px
.v-list-item__icon
  margin-right: 5px !important
</style>
